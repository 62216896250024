:root {
    --fs-small-text: 0.875rem;
    --fs-text: 1rem;
    --fs-heading1: clamp(1.8rem, 2vw, 2.3rem);
    --fs-heading2: clamp(1.4rem, 2vw, 2rem);
    --fs-heading3: clamp(1.2rem, 2vw, 1.5rem);
    --fw-regular: 400;
    --fw-medium: 600;
    --fw-bold: 700;
    --fw-extra-bold: 900;
    --active-nav-clr: #cdf6d7;
    --black-clr: #000;
    --primary-clr-dark: #2b2b2b;
    --primary-clr-light: #fff;
    --secondary-clr: #004012;
    --secondary-clr-btn: #17B844;
    --alt-clr: #E3F0E6;
    --alt-dark-clr: #1c1c1c;
    --input-field-clr: #F6F7F7;
    --xtra-small: 0.5rem;
    --small: 0.75rem;
    --medium: 1rem;
    --big: 1.4rem;
    --large: 1.8rem;
    --semi-large: 2rem;
    --xtra-large: 3rem;
    --xtra-xtra-large: 4rem
}

.eventIconText,
.timeLeft {
    font-weight: var(--fw-medium)
}

.allEventDetailsContainer {
    width: 80%;
    display: grid;
    gap: var(--xtra-large);
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    place-content: center;
    justify-content: space-between;
    padding: var(--xtra-large) 0
}

.eventDetailsBackBtn {
    width: 80%
}

.eventDetailsBackBtn button {
    background-color: var(--secondary-clr-btn)
}

.eventDetailsImgContainer {
    max-height: 500px;
    border-radius: var(--medium);
    overflow: hidden
}

.eventDetailsImgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.eventDetails {
    display: grid;
    gap: var(--medium)
}

.eventIconText {
    display: flex;
    gap: var(--xtra-small)
}

.countdownContainer {
    display: grid;
    width: 100%;
    gap: var(--medium);
    grid-column: 1/3;
    place-self: center;
    text-align: center;
    padding: var(--medium);
    color: var(--primary-clr-light);
    background: rgba(0, 0, 0, .849);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, .705)
}

.countdownCard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4.5rem, 1fr));
    gap: var(--small);
    color: #fb8989
}

.timeLeft {
    font-size: var(--fs-heading1)
}

@media screen and (max-width:67.5rem) {
    .allEventDetailsContainer {
        display: flex;
        flex-direction: column
    }
}