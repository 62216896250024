@media screen and (max-width:75rem) {
    .fa-bars-staggered {
        color: var(--black-clr)
    }

    .nameInputFields {
        display: grid;
        grid-template-columns: 1fr
    }
}

@media screen and (max-width:67.5rem) {

    .hamburger,
    .hamburger.isOpen {
        transition: transform .3s ease-in-out
    }

    .aboutSection,
    .leftPane {
        gap: var(--xtra-large)
    }

    .eventCards,
    .eventHeader,
    .hero {
        width: 90%
    }

    .aboutLink {
        width: 100%
    }

    .cta,
    .ctaSecondary {
        padding: var(--big);
        width: 100%;
        justify-content: center
    }

    .mobileHeight {
        height: 100dvh
    }

    .navBar {
        min-width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center
    }

    .navBtn,
    header .navItems {
        display: none;
        width: fit-content
    }

    .mobileHeaderOnly {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    .navItems.isOpen {
        z-index: -1;
        width: 100dvw;
        height: 100dvh;
        position: absolute;
        top: 0;
        right: 0;
        padding: var(--large);
        background-color: var(--primary-clr-light);
        display: flex;
        flex-direction: column;
        gap: var(--semi-large);
        text-align: center;
        scale: 1.2
    }

    .navBtn.isOpen {
        display: flex
    }

    .no-scroll {
        overflow: hidden
    }

    .hamburger {
        display: flex
    }

    .hamburger.isOpen {
        transform: rotateY(180deg)
    }

    .aboutSection {
        grid-template-columns: auto;
        text-align: center;
        width: 90%
    }

    .contactSection,
    .eventCards {
        grid-template-columns: 1fr
    }

    .aboutText>p {
        max-width: 100%
    }

    .allEventsCta {
        padding: var(--small)
    }

    .contactSection {
        width: 90%;
        justify-content: center;
        align-items: center
    }

    .nameInputFields {
        flex-direction: column;
        gap: var(--small)
    }

    .footerNavBar,
    .footerNavBar .navItems {
        display: grid;
        gap: var(--semi-large)
    }

    .footerNavBar {
        place-content: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: var(--semi-large) 0
    }

    .footerNavItem,
    .socials i {
        padding: var(--medium)
    }

    .footerNavBar .socials {
        justify-content: center;
        align-items: center
    }
}

@media screen and (max-width:33.5rem) {
    .mobileWidth {
        width: 90%;
        margin: auto
    }

    .hero video {
        aspect-ratio: 1/1.5;
        object-fit: cover
    }

    .aboutSection {
        width: 90%
    }

    .eventCard,
    .eventCards {
        grid-template-columns: 1fr
    }

    .eventImageContainer {
        grid-row-start: 1
    }
}