.hamburger,
.hamburger:hover {
  background: 0 0
}

a {
  text-decoration: none;
}

.desktopMaxWidth,
.navBar {
  max-width: 1440px;
  margin: auto
}

.headerLogo,
.navItem.active,
body {
  color: var(--primary-clr-dark)
}

.eventCardMain,
.footerNavItem li,
.hamburger,
.navItems li {
  transition: scale .2s ease-in-out
}

.contactForm a,
.eventHeader a,
.footerNavBar .logo,
.link {
  text-decoration: none
}

.notFound,
.successMessage,
.textOverlay {
  place-content: center
}

.copyright,
.noEventsContainer,
.noUpcomingEventsText,
.notFound,
.privacyText,
.textOverlay {
  text-align: center
}

*,
::after,
::before {
  box-sizing: border-box
}

* {
  margin: 0;
  padding: 0
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased
}

canvas,
img,
picture,
svg,
video {
  display: block;
  max-width: 100%
}

button,
input,
select,
textarea {
  font: inherit
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  overflow-wrap: break-word
}

body {
  margin: auto;
  width: 100dvw;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  font-size: 16px
}

.submissionStatusContainer {
  display: none;
  font-size: var(--fs-text);
  padding: var(--medium);
  text-align: center
}

.submissionStatusContainer.visible,
video {
  display: block
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh
}

.mainContent {
  flex: 1
}

.heading3 {
  font-size: var(--fs-heading2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-heading3);
  font-weight: var(--fw-medium)
}

.aboutLink {
  width: fit-content
}

.hamburger {
  display: none;
  border: none;
  font-size: var(--fs-heading2)
}

.logo {
  cursor: pointer;
  font-size: var(--fs-heading3);
  font-weight: var(--fw-bold)
}

.logo img {
  min-width: 150px
}

.logo>span {
  font-size: var(--fs-text);
  font-weight: var(--fw-regular)
}

header {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: var(--primary-clr-light);
  color: #000;
  margin-bottom: var(--large);
  background: rgba(255, 255, 255, .3);
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border-bottom: 1px solid rgba(240, 240, 240, .67)
}

.formHeader>hr,
hr {
  border-width: .5px
}

.navBar {
  width: 80%;
  display: flex;
  gap: var(--large);
  padding: var(--medium) 0;
  justify-content: space-between;
  align-items: center
}

.navBtn {
  width: min-content
}

.footerNavItems,
.navItems {
  display: flex;
  list-style-type: none;
  gap: var(--large);
  justify-content: center;
  align-items: center
}

.navItem.active {
  background-color: var(--active-nav-clr);
  border-radius: var(--small)
}

.footerNavItem,
.navItem,
.navItemAlt {
  margin: auto;
  cursor: pointer;
  padding: var(--small);
  font-size: var(--fs-small-text);
  font-weight: var(--fw-medium);
  text-decoration: none;
  color: var(--black-clr)
}

.cta,
.ctaSecondary {
  display: inline-flex;
  gap: var(--small);
  font-size: var(--fs-small-text);
  font-weight: var(--fw-medium);
  padding: var(--small) var(--medium)
}

.cta,
.footerNavItem,
.socials>*,
footer .logo {
  color: var(--primary-clr-light)
}

.cta,
.ctaSecondary,
.formBtn {
  transition: background-color .2s ease-in-out;
  cursor: pointer
}

.footerNavItems li:hover,
.navItems li:hover {
  scale: 1.1
}

.cta {
  width: fit-content;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  border: none;
  border-radius: var(--small);
  background-color: var(--secondary-clr)
}

.aboutSection,
.eventHeader {
  justify-content: space-between
}

.ctaSecondary {
  border: none;
  border-radius: var(--small);
  background-color: var(--primary-clr-light);
  color: var(--primary-clr-dark)
}

.eventTitle,
.textOverlay,
.upcomingEventsText {
  font-weight: var(--fw-bold)
}

.allEventsCta,
.eventBtn:hover,
.eventCard,
.eventSection,
.textOverlay {
  color: var(--primary-clr-light)
}

.cta:hover,
button:hover {
  background-color: var(--secondary-clr-btn)
}

.homeContainer {
  display: grid;
  margin: auto;
  gap: var(--xtra-xtra-large)
}

.hero {
  width: min(80%, 1440px);
  margin: 0 auto
}

.videoContainer {
  position: relative;
  border-radius: var(--medium);
  overflow: hidden;
  max-width: 100%
}

.colorOverlay,
.textOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

video {
  width: 100%;
  height: auto
}

.textOverlay {
  display: grid;
  padding: var(--medium);
  font-size: clamp(1.6rem, 3vw, 2.5rem)
}

.colorOverlay {
  background-color: rgba(0, 0, 0, .5)
}

.aboutSection,
.eventCards,
.eventHeader {
  width: 80%;
  margin: auto
}

.aboutSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  align-items: center
}

.aboutUs,
.formGroupContainer {
  display: grid;
  gap: var(--large)
}

.aboutText {
  display: grid;
  gap: var(--xtra-small)
}

.swiperContainer {
  border: none;
  border-radius: var(--medium);
  overflow: hidden
}

.allEventsContainer {
  display: grid;
  gap: var(--xtra-xtra-large)
}

.eventSection {
  display: grid;
  gap: var(--xtra-large);
  max-width: 100%;
  background: url(../images/africanPattern.png) rgba(1, 1, 1, .3);
  background-size: cover;
  background-blend-mode: multiply;
  padding: var(--xtra-xtra-large) 0
}

.eventHeader {
  display: flex;
  align-items: center;
  gap: var(--medium)
}

.ifLoading {
  display: grid;
}

.eventCard,
.eventCards {
  justify-content: space-between
}

.upcomingEventsText {
  font-size: var(--fs-heading3)
}

.eventCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: var(--xtra-large)
}

.eventCard,
.eventInfo,
.eventItems,
.noEventsContainer {
  display: grid;
  gap: var(--medium)
}

.eventCard {
  height: 100%;
  grid-template-columns: 1fr 1fr;
  padding: var(--big);
  cursor: pointer;
  background: rgba(255, 255, 255, .1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, .3)
}

.eventCardMain:hover {
  scale: 1.05
}

.eventItems {
  grid-template-columns: 1fr;
  justify-content: space-between
}

.eventTitle {
  font-size: larger
}

.emailTitle,
.eventHeadline,
.formBtn,
label {
  font-weight: var(--fw-medium)
}

.allEventsCta,
.eventBtn:hover {
  background-color: var(--secondary-clr-btn)
}

.submissionStatus.Success,
.successMessage {
  color: var(--secondary-clr-btn)
}

.eventBtn {
  width: fit-content;
  height: fit-content;
  align-self: flex-end
}

.eventImageContainer {
  min-height: 100%;
  max-height: 15rem;
  border-radius: var(--small);
  overflow: hidden;
  background-color: #2c2c2e
}

.contactCard,
iframe {
  border-radius: var(--medium)
}

.eventImage {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.noEventsContainer {
  justify-content: center;
  width: 100%
}

.noUpcomingEventsText {
  width: 100%;
  font-size: var(--fs-heading3);
  padding: var(--medium) var(--xtra-large)
}

.contactCardText,
.contactFormText,
.formBtn,
label {
  font-size: var(--fs-small-text)
}

.loadingAnimationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: var(--medium)
}

.contactCard,
.contactForm,
footer {
  padding: var(--large)
}

.contactSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--xtra-large);
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  margin-bottom: var(--xtra-xtra-large);
  width: 80%
}

.leftPane {
  display: grid;
  grid-template-rows: .5fr 1fr;
  gap: var(--large);
  height: 100%
}

iframe {
  height: 100%;
  margin: auto
}

.contactCard {
  display: grid;
  gap: var(--medium);
  background-color: var(--alt-clr)
}

.contactCard a {
  text-decoration: none;
  color: #2600ff;
}

.emailTab,
.phoneTab {
  display: flex;
  align-items: center;
  gap: var(--medium)
}

.callIcon,
.mailIcon {
  min-width: 48px
}

::placeholder {
  font-size: var(--fs-small-text)
}

.contactForm {
  display: grid;
  gap: var(--large);
  background: var(--primary-clr-light);
  border-radius: var(--big);
  box-shadow: 15px 15px 30px #efefef, -15px -15px 30px #efefef;
  max-width: 100%
}

.successMessage {
  display: grid
}

.successMessage>* {
  place-self: center
}

.submissionStatus.error {
  color: red
}

.contactFormText {
  margin-bottom: var(--medium);
  color: #555
}

.copyright,
.formBtn {
  color: var(--primary-clr-light)
}

.formHeader>hr {
  width: 100%;
  border-color: #ffffff77;
  color: #fff
}

.formGroup {
  display: flex;
  flex-direction: column
}

.nameInputFields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--medium)
}

.nameInputFields div {
  width: 100%
}

.formGroup input,
.formGroup textarea {
  padding: var(--medium);
  border: none;
  border-radius: var(--small);
  font-size: var(--fs-text);
  background-color: var(--input-field-clr);
  width: 100%
}

.formGroup textarea {
  resize: vertical;
  height: 100px
}

.formBtn {
  width: 100%;
  border: none;
  border-radius: var(--small);
  padding: var(--small) var(--medium);
  background-color: var(--secondary-clr)
}

.contactForm a {
  color: #2600ff
}

.contactForm a:hover {
  text-decoration: underline
}

.privacyText {
  margin-top: var(--small)
}

footer {
  display: flex;
  flex-direction: column;
  gap: var(--medium);
  border-radius: var(--large) var(--large) 0 0;
  background-color: var(--black-clr)
}

.footerNavBar {
  display: flex;
  width: 100%;
  max-width: 80%;
  margin: auto;
  padding: var(--medium) 0;
  justify-content: space-between;
  align-items: center
}

.socials {
  display: flex;
  gap: var(--large);
  cursor: pointer
}

.socials>* {
  scale: 1.5;
  transition: scale .2s ease-in-out
}

.socials>:hover {
  scale: 1.8
}

hr {
  width: 80%;
  margin: auto;
  border-color: #ffffff29
}

.copyright {
  padding: var(--big);
  font-size: var(--fs-small-text);
  font-weight: 300
}

.homeContainer>*,
article {
  opacity: .3;
  transform: translateX(-2rem);
  transition: opacity .8s ease-in-out, transform .8s ease-in-out
}

.homeContainer>.visible,
article.visible {
  opacity: 1;
  transform: translateY(0)
}

.notFound {
  display: grid;
  margin: auto;
  padding: var(--xtra-large)
}