@media screen and (max-width:67.5rem) {

    .aboutHeroContainer h3,
    .aboutHeroContainer p {
        max-width: 90%
    }

    .aboutCard,
    .coreValuesContainer,
    .teamContainer {
        width: 90%
    }

    .aboutCard {
        gap: var(--large)
    }

    .aboutCardTagline {
        max-width: 100%
    }

    .coreValueContainer {
        gap: var(--medium);
        grid-template-columns: 1fr
    }

    .coreValueContainer.reverse {
        display: flex;
        flex-direction: column-reverse
    }

    .coreValueImgContainer {
        max-height: 350px
    }
}

@media screen and (max-width:36.88rem) {
    .aboutCard {
        grid-template-columns: 1fr
    }
}